<template>
  <div class="space-y-4">
    <article v-if="canEditItemTypes">
      <h3 class="subheader">
        Quick Links
      </h3>
      <br />
      <section
        class="relative z-0  rounded-md grid sm:grid-cols-1 md:grid-cols-4"
      >
        <asom-button
          variant="primary"
          outline
          rounded="none"
          @click="
            $router.push({
              name: 'Create Item Type',
            })
          "
          icon="file-document"
          text="Create Item Type"
        >
        </asom-button>
      </section>
    </article>
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <asom-card>
      <div class="sm:w-1/2">
        <asom-form-field label="Inventory Group">
          <asom-input-select
            v-model="filteredInventoryGroup"
            :options="inventoryGroups"
            placeholder="Select an Inventory Group"
          />
        </asom-form-field>
      </div>
    </asom-card>
    <div class="pt-8">
      <asom-server-table
        :key="tableKey"
        :url="url"
        :columns="tableColumns"
      >
        <template v-slot:header_inventoryTypeName>Item Type Name</template>
        <template v-slot:header_qrCodeString>QR Code</template>
        <template v-slot:qrCodeString="scopedSlots">
          <div class="flex flex-wrap justify-start">
            <div class="w-3/12 pr-4">
              <img id="qr-code"
                alt="qr"
                :src="getBase64Image(scopedSlots.data)"
                class="shadow-lg rounded max-w-full h-auto align-middle border-none cursor-pointer"
                @click="
                  downloadImage(
                    getBase64Image(scopedSlots.data),
                    get(scopedSlots.rowData, 'inventoryTypeName', 'QR Code')
                  )
                "
              />
            </div>
          </div>
        </template>
        <template v-slot:action="scopedSlots" v-if="canEditItemTypes">
          <div class="flex flex-col gap-1 w-48">
            <asom-button
              size="sm"
              outline
              text="Delete"
              variant="error"
              @click="deleteInventoryTypeClicked(scopedSlots.rowData)"
            />
            <asom-button
              size="sm"
              outline
              text="Update"
              @click="navigateToUpdate(scopedSlots.rowData)"
            />
          </div>
        </template>
      </asom-server-table>
    </div>
    <asom-modal
      title="Delete Inventory Type"
      v-model="showModal"
      :dismissible="false"
    >
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div class="flex flex-row-reverse">
        <div class="pl-4">
          <asom-button
            @click="onDeleteInventoryType"
            text="OK"
            :loading="isSubmitting"
          />
        </div>
        <div>
          <asom-button
            @click="toggleModal(false)"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
  </div>
</template>

<script>
import get from "lodash.get";
import { mapGetters } from "vuex";
import {
  deleteInventoryType,
} from "../../../../services/inventory.service";
import { InventoryGroups } from "../../../../constants/APIEnums/inventoryEnums";
import {
  getBase64Image,
  downloadImage,
} from "../../../../helpers/imageDataHelper";
import { GET_INVENTORY_TYPE_LIST } from "../../../../constants/apis";

export default {
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      typeList: [],
      tableData: [],
      error: null,
      showModal: false,
      selectedItem: null,
      filteredInventoryGroup: null,
      tableKeyIndex: 0,
    };
  },
  computed: {
    ...mapGetters({
      stationId: "selectedStation/id",
      canEditItemTypes: "auth/canEditItemTypes",
    }),
    tableColumns() {
      let columns = [
        "inventoryTypeName",
        "inventoryGroup",
        "remarks",
        "qrCodeString",
      ];
      if (this.canEditItemTypes) columns.push("action");
      return columns;
    },
    inventoryGroups() {
      return [
        {
          label: InventoryGroups.GENERAL.NAME,
          value: InventoryGroups.GENERAL.VALUE,
        },
        {
          label: InventoryGroups.SMART_CARD.NAME,
          value: InventoryGroups.SMART_CARD.VALUE,
        },
        {
          label: InventoryGroups.SERIALISED_TAG.NAME,
          value: InventoryGroups.SERIALISED_TAG.VALUE,
        },
        {
          label: InventoryGroups.FIRST_AID.NAME,
          value: InventoryGroups.FIRST_AID.VALUE,
        },
      ];
    },
    selectedInventoryGroup() {
      return get(this.filteredInventoryGroup, "value", null);
    },
    url() {
      return GET_INVENTORY_TYPE_LIST 
        + `?stationId=${this.stationId}` 
        + (this.selectedInventoryGroup ? `&inventoryGroup=${this.selectedInventoryGroup}` : '')
    },
    tableKey() {
      return `inventory-type-table-${this.selectedInventoryGroup || 'all-group'}-${this.tableKeyIndex}`
    }
  },
  methods: {
    get,
    getBase64Image,
    downloadImage,
    navigateToUpdate(selectedItem) {
      this.$router.push({
        name: "Update Item Type",
        params: selectedItem,
      });
    },
    deleteInventoryTypeClicked(selectedItem) {
      this.selectedItem = selectedItem;
      this.toggleModal(true);
    },
    toggleModal(value) {
      this.showModal = value;
    },
    async onDeleteInventoryType() {
      this.isSubmitting = true;
      const result = await deleteInventoryType({
        inventoryTypeId: get(this.selectedItem, "inventoryTypeId"),
      });
      if (result.success) {
        this.isSubmitting = false;
        this.toggleModal(false);
        this.tableKeyIndex++; // force reload data
      } else {
        this.isSubmitting = false;
        this.error = result.payload;
        this.toggleModal(false);
        this.$scrollTop();
      }
    },
  },
};
</script>
<style scoped>
  #qr-code{
    min-width: 4rem;
  }
</style>