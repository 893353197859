export function getBase64Image(qrCodeString) {
  var image = new Image();
  return (image.src = `data:image/png;base64,` + qrCodeString);
}

export const downloadImage = async (imageSrc, filename) => {
  const canvas = document.createElement("canvas");
  canvas.width = '256';
  canvas.height = '256';
  const ctx=canvas.getContext("2d");
  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, 256, 256);

  const imgage = new Image();
  imgage.src = imageSrc;
  imgage.onload = function() {
    ctx.drawImage(imgage, 0, 0, 256,256);
    const imageURL = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = imageURL;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
};
